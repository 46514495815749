import React from "react";
import SectionHeader from "../components/sections/sectionheader.js";
import SectionServices from "../components/sections/sectionservices.js";
import SectionWork from "../components/sections/sectionwork.js";
import SectionAbout from "../components/sections/sectionabout.js";
import SectionContact from "../components/sections/sectioncontact.js";
import Layout from "../layouts/en";
import { scrollElementIntoView } from "../components/helper";

function HomePage(props) {
  if (typeof document !== `undefined`) {
    document.documentElement.classList.remove('nav-open');
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (typeof window !== `undefined` && window.location.hash !== '') {
      if (document.getElementById(window.location.hash.replace('#', '')) !== null) {
        setTimeout(() => {
          scrollElementIntoView(document.getElementById(window.location.hash.replace('#', '')));
        }, 500);
      }
    }
  });
  return (
    <Layout location={props.location} title="newwww.agency | Full-service web agency from Karlsruhe" description="We are a full-service web agency based in Karlsruhe and can do everything that has to do with the web: strategy & consulting, UX / UI design, websites & apps, and e-commerce & shops." keywords="Webagency, Karlsruhe, Strategy & Consulting, UI/UX-Design, Webapps, Websites, E-Commerce, Online-Shops, " robots="index, follow, notranslate">
      <SectionHeader />
      <SectionServices />
      <SectionWork />
      <SectionAbout />
      <SectionContact lang="en" />
    </Layout>
  );
}

export default HomePage;
